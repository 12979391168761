@keyframes checkIn {
    from {background-color: #555555;}
    to {background-color: none;}
}

.newCheckIn {
    animation-name: checkIn;
    animation-duration: 1s;
    /* scroll-behavior: smooth; */
}

/* .MuiSwitch-colorPrimary.Mui-disabled {
    color: red !important;
} */

@keyframes checkOut {
    from {background-color: #555555;}
    to {background-color: none;}
    
}

.newCheckOut {
    animation-name: checkOut;
    animation-duration: 1s;
    /* scroll-behavior:auto */
}