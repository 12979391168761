@-moz-keyframes move-astronaut {
    100% {
        -moz-transform: translate(-160px, -160px);
    }
}
@-webkit-keyframes move-astronaut {
    100% {
        -webkit-transform: translate(-160px, -160px);
    }
}
@keyframes move-astronaut {
    100% {
        -webkit-transform: translate(-160px, -160px);
        transform: translate(-160px, -160px);
    }
}
@-moz-keyframes rotate-astronaut {
    100% {
        -moz-transform: rotate(-720deg);
    }
}
@-webkit-keyframes rotate-astronaut {
    100% {
        -webkit-transform: rotate(-720deg);
    }
}
@keyframes rotate-astronaut {
    100% {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}

.stars {
    background: url(http://salehriaz.com/404Page/img/overlay_stars.svg);
    background-repeat: repeat;
    background-size: contain;
    background-position: left top;
}
.object_astronaut {
    animation: rotate-astronaut 200s infinite linear both alternate;
}
